import '../../styles/homeandlifepage.css';

import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import bitmap_2 from '../../images/bitmap_2.png';
// import Illustration from '../../images/illustration-bubble-tip.png';
import bitmap_1 from '../../images/images/bitmap.png';
// import girlwithcat from '../../images/images/group-3.png';
import PH_Image from '../../images/images/group-8.png';
import righttick from '../../images/images/icon-tick-green.svg';
// import bitmap_3 from '../../images/images/ratingThree.png';
import stillwaterlogo from '../../images/stillwater-logo-horiz.png';

const StillwaterAutoPage = ( { data, parent } ) => {

  return (
    <Layout header={'menu'}>
      <SEO
        title="Auto insurance by Stillwater, Stillwater, Best Life Insurance Policy, Life insurance quotes online, Online insurance"
        canonicalUrl='https://www.getmybubble.com/stillwaterauto/'
        description="Auto insurance by Stillwater, Stillwater, Auto insurance quotes online, Online  home insurance policy"
        keywords="Auto insurance by Stillwater, Stillwater, Auto insurance quotes online, Online  home insurance policy"
      />

      {/* <Menu /> */}

      <div className="flexColumn carrierCommonPage">
        <div className="container-fluid carrier-plan-container_1">
          {/* carrier-plan-container */}

          <div className="container">
            <div className="row">
              <div className="col-lg-6 ">
                <div className="padding-left">
                  <img src={stillwaterlogo} alt="logo" width="200"/>

                  <h1 className="carrier-plan-heading">Auto insurance</h1>

                  <p className="carrier-plan-section1-para">
                    Stillwater offers more than just competitive rates. They offer quality, value and outstanding service.
                  </p>

                  <div className="flexRow align-btns ml-1">
                    <Link to="/">
                      <button type="button" className="orangeBtn mr-3">
                      Get a quote now
                      </button>
                    </Link>

                    <a href="#start-exploring" style={{ color: '#ff7007', textDecoration: 'none' }}>
                      <button type="button" className="explore">
                        Explore
                      </button>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 sbli-image" style={{ textAlign: 'center' }}>
                <img src="https://cdn.getmybubble.com/images/corporate-sites/auto-insurance.svg" className="carrier-right-img-mv mt-4 mt-sm-4 mt-md-4 mt-lg-0 auto-image" alt="" />
              </div>
            </div>
          </div>

        </div>

        {/*section 2*/}

        <div id="start-exploring" className="container-fluid" style={{ marginTop: '80px' }}>
          <div className="row">
            <div className="col-lg-3"></div>

            <div className="col-lg-6 costom-padding19">
              <h3 className="stillwater-title1">
                Why to choose Stillwater's Auto Insurance?
              </h3>

              <p className="stillwater-para1">
                Get quality coverage*, personalized to your needs.
                With Stillwater, you and your agent can build an affordable policy with coverages and limits ... that help you drive confidently.
              </p>

            </div>

            <div className="col-lg-3"></div>
          </div>
        </div>

        {/* Product Highlights Section */}

        <div className="container-fluid">
          <div className="row" style={{ marginTop: '30px' }}>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-2"></div>

                <div className="col-lg-9 product-container-alignment costom-padding19">
                  <div className="stillwater-product-container">

                    <div className="align-horizontal-center">
                      <img src={PH_Image} className="ph-desktop-view" alt="phdesk"/>

                      <h3
                        className="ph-title ph-desktop-view">
                          Get rewarded with money-saving discounts
                      </h3>

                      <h3
                        className="ph-title ph-mobile-view">
                          Get rewarded with money-saving discounts
                      </h3>

                      <img src={PH_Image} className="ph-mobile-view" alt="phmob"/>

                      <p>Stillwater’s already-low rates get even better with discounts. Here are just a few we offer:</p>
                    </div>

                    <div
                      style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>

                      <img src={righttick} className="img-responsive" alt="tick" style={{ verticalAlign: 'middle' , margin: '0' }}></img>

                      <div className="ph-item-style pl-3">
                        Accident/Violation Free
                      </div>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} className="img-responsive" alt="tick" style={{ verticalAlign: 'middle' , margin: '0' }} />

                      <div className="ph-item-style pl-3">
                        Multi-policy
                      </div>

                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} className="img-responsive" alt="tick" style={{ verticalAlign: 'middle' , margin: '0' }}/>

                      <div className="ph-item-style pl-3">
                        Good Driver
                      </div></div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} className="img-responsive" alt="tick" style={{ verticalAlign: 'middle' , margin: '0' }}/>

                      <div className="ph-item-style pl-3">
                        Paid-in-Full
                      </div></div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} className="img-responsive" alt="tick" style={{ verticalAlign: 'middle' , margin: '0' }}/>

                      <div className="ph-item-style pl-3">
                        Good Student
                      </div></div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} className="img-responsive" alt="tick" style={{ verticalAlign: 'middle' , margin: '0' }}/>

                      <div className="ph-item-style pl-3">
                        Paperless
                      </div></div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} className="img-responsive" alt="tick" style={{ verticalAlign: 'middle' , margin: '0' }}/>

                      <div className="ph-item-style pl-3">
                        Multi-Car
                      </div></div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} className="img-responsive" alt="tick" style={{ verticalAlign: 'middle' , margin: '0' }}/>

                      <div className="ph-item-style pl-3">
                        Theft Prevention and Safety Device
                      </div></div>

                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.5rem' }}>
                      <img src={righttick} className="img-responsive" alt="tick" style={{ verticalAlign: 'middle' , margin: '0' }}/>

                      <div className="ph-item-style pl-3">
                        Your Stillwater agent can tell you which discounts you’re eligible for, and show you other ways you can save.
                      </div></div>

                  </div>

                </div>

                <div className="col-lg-1"></div>
              </div>
            </div>

            <div className="col-lg-6 carrier-middle-container carrier-middle-container-mv">
              <h4 className="stillwater-col2-title">Standard Coverages:</h4>

              <p>These coverages help protect you, your passengers, or other people on the road in the event of an accident.</p>

              <ul className="stillwater-list stillwater-list-item stillwater-list-mv common-width">
                <li>
                  <b>Bodily injury liability</b>

                  <p>Pays if you cause an accident that injures another driver and/or passengers</p>
                </li>

                <li>
                  <b>Property damage liability</b>

                  <p>Pays if you cause an accident that damages someone’s property</p>
                </li>

                <li>
                  <b>Personal injury protection</b>

                  <p>Pays medical bills for any person injured in your car in an accident, regardless of who’s at fault. (Available only in select states)</p>
                </li>

                <li>
                  <b>Uninsured/Underinsured Motorist</b>

                  <p>Pays if you’re in an accident caused by someone with little or no insurance</p>
                </li>

                <li>
                  <b>Medical payments</b>

                  <p>Pays for necessary medical treatments, and other costs (including ambulance transportation, x-rays or nursing care), in the event of an accident</p>
                </li>

              </ul>

              <h4 className="stillwater-col2-title">Optional Coverages:</h4>

              <p>These coverages help you with repairing any damage done to your vehicle or another driver’s, as well as providing additional resources if you can no longer use your own car.</p>

              <ul className="stillwater-list stillwater-list-item stillwater-list-mv common-width">
                <li>
                  <b>Collision</b>

                  <p>Pays for physical damage to your car when it collides with another object, such as a tree or a second vehicle</p>
                </li>

                <li>
                  <b>Comprehensive</b>

                  <p>Pays if your car is stolen, or damaged by something other than a collision with another vehicle (examples include fire, flooding or hitting an animal)</p>
                </li>

                <li>
                  <b>Towing</b>

                  <p>Reimburses you if your car needs to be towed to a shop for a covered repair</p>
                </li>

                <li>
                  <b>Rental</b>

                  <p>Reimburses your rental costs if your car is being repaired under a collision or comprehensive claim</p>
                </li>

              </ul>


            </div>
          </div>
        </div>

        <div className="container stillwater-card-container">
          <div className="row">
            <div className="col-lg-3">
            </div>

            <div className="col-lg-6">
              <h3 className="stillwater-title1">When it comes to protecting your assets, we make the grade.</h3>

              <div className="card-deck align-stillwater-cards">

                <div className="row">
                  <div className="col-md-6 col-lg-6 section30 costom-padding19">
                    <div className="card stillwater-cards">
                      <div className="card-header align-stillwater-card-header">
                        <img src={bitmap_1} alt="bitmap"/>
                      </div>

                      <div className="card-body bg-card-body">
                        <p className="stillwater-card-text">Has the financial strength and capability to guarantee our customers' polices and keep them secure.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-6 section30 costom-padding19">
                    <div className="card stillwater-cards">
                      <div className="card-header align-stillwater-card-header">
                        <img src={bitmap_2} alt="bitmap"/>
                      </div>

                      <div className="card-body bg-card-body">
                        <p className="stillwater-card-text">Proven track record of predicting financial stability in the insurance industry and substantiates</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-2">
          </div>
        </div>

        <div className="plansForYou">
          <div className="orangeBackGround">
            <div>
              Find out which plan is right for you
            </div>

            <div className="customizedPlan">
              Policies are customized according to your needs.
              We have simplified the process to help you find the right policy that fits your budget and loved ones.
            </div>

            <Link to="/" type="button" className="btnWhite">
              Get your quote now
            </Link>
          </div>
        </div>
      </div>
    </Layout> );
};

export default StillwaterAutoPage;
